/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './family-view.scss';

/** Components */
import { ChildAccountManager } from './child-account-manager/child-account-manager';

interface FamilyViewProps
{
	children: Array<Child>,
	unlinkChildAccount: (childId:string)=>void,
	deleteChildAccount: (childId:string)=>void,
}

interface FamilyViewState
{
	
}

export class FamilyView extends Component<FamilyViewProps, FamilyViewState>
{
	constructor(props:FamilyViewProps)
	{
		super(props);
	}

	private renderChild = (child:Child, i:number) => (
		<ChildAccountManager key={ i } child={ child } unlinkChildAccount={ this.props.unlinkChildAccount } deleteChildAccount={ this.props.deleteChildAccount } />
	);

	render()
	{
		const children = this.props.children.map((child, i) => this.renderChild(child, i));
		return (
			<div className="family-view">
				<h1>Family Management</h1>
				<div className="-two-cols">
					{ children }
				</div>
			</div>
		);
	}
}

/** NPM Packages */
import React from "react";
import { render } from "react-dom";

/** SCSS */
import './reset.scss';
import './base.scss';

/** Components */
import { Router } from './router';

render(<Router />, document.body.querySelector('app-shell'));

/** NPM Packages */
import React, { Component, FormEventHandler, FormEvent } from 'react';
import { RouterProps } from "react-router";
import { Link } from "react-router-dom";

/** CSS */
import './forgotten-password-form.scss';

/** Components */
import { OutlineInput } from '../../outline-input/outline-input';
import { Button } from '../../button/button';

type ForgottenPasswordFormProps = {
	context: any,
}

type ForgottenPasswordFormState = {
	email: { value: string, error: string|null },
}

interface FormRejection
{
	input: string,
	error: string,
}

export class ForgottenPasswordForm extends Component<ForgottenPasswordFormProps, ForgottenPasswordFormState>
{
	private _context : RouterProps;

	constructor(props:ForgottenPasswordFormProps)
	{
		super(props);
		this._context = props.context;
		this.state = {
			email: {
				value: '',
				error: null
			}
		};
	}

	private handleFormSubmit:FormEventHandler = this.submit.bind(this);

	private verifyFormInputs() : Promise<any>
	{
		return new Promise((resolve, reject)=>{
			if (!this.state.email.value.match(/^\S+@\S+\.\S+$/g))
			{
				reject({ input: 'email', error: 'Please provide a valid email address.' });
			}

			resolve();
		});
	}

	private submit(e:FormEvent) : void
	{
		e.preventDefault();
		console.warn('Password reset logic has not be implemented yet');

		this.verifyFormInputs()
		.then(() => {
			this._context.history.push('/forgotten-password/sent');
		})
		.catch((rejection:FormRejection) => {
			const updatedState = { ...this.state };

			switch (rejection.input)
			{
				case 'email':
					updatedState.email.error = rejection.error;
					break;
			}
			this.setState(updatedState);
		});
	}

	public updateFormState(key:string, value:string) : void
	{
		const updatedState = { ...this.state };
		updatedState.email.error = null;
		
		switch (key)
		{
			case 'email':
				updatedState.email.value = value;
				break;
		}
		this.setState(updatedState);
	}

	render()
	{
		return (
			<form noValidate className="forgotten-password-form" onSubmit={ this.handleFormSubmit }>
				<div>
					<h1>Enter your email address</h1>
				</div>
				<OutlineInput type="email" required={ true } label="Email" name="email" form={ this.updateFormState.bind(this) } error={ this.state.email.error } />
				<div className="-justify-right">
					<Link to="/" className="-button">Back</Link>
					<Button type="submit" label="Submit" classes="-solid -primary" />
				</div>
			</form>
		);
	}
}

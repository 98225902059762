import React, { Component, FormEventHandler, FormEvent, createRef, RefObject } from "react";
import { Link } from "react-router-dom";
import { RouterProps } from "react-router";

/** CSS */
import './login-form.scss';

/** Components */
import { OutlineInput } from '../../outline-input/outline-input';
import { Button } from '../../button/button';

interface LoginFormProp
{
	context: any
}

interface LoginFormState
{
	email: { value: string, error: string|null },
	password: { value: string, error: string|null },
}


export class LoginForm extends Component<LoginFormProp, LoginFormState>
{
	private _context : RouterProps;

	private _emailInput : RefObject<OutlineInput>;
	private _passwordInput : RefObject<OutlineInput>;

	constructor(props:LoginFormProp)
	{
		super(props);
		this._context = props.context;
		this.state = { email: { value: '', error: null }, password: { value: '', error: null } };

		this._emailInput = createRef<OutlineInput>();
		this._passwordInput = createRef<OutlineInput>();
	}

	private handleLoginSubmit:FormEventHandler = this.login.bind(this);

	private verifyFormInputs() : Promise<any>
	{
		return new Promise((resolve, reject)=>{
			if (!this.state.email.value.match(/^\S+@\S+\.\S+$/g))
			{
				reject({ input: 'emailAddress', error: 'Please provide a valid email address.' });
			}
			else if (this.state.password.value.length < 8)
			{
				reject({ input: 'password', error: 'Password is too short.' });
			}

			resolve();
		});
	}

	private login(e:FormEvent) : void
	{
		e.preventDefault();
		console.warn('Login logic has not be implemented yet');
		
		this.verifyFormInputs()
		.then(() => {
			this._context.history.push('/dashboard');
		})
		.catch((rejection:FormRejection) => {
			const updatedState = { ...this.state };

			switch (rejection.input)
			{
				case 'emailAddress':
					updatedState.email.error = rejection.error;
					break;
				case 'password':
					updatedState.password.error = rejection.error;
					break;
			}
			this.setState(updatedState);
		});
	}

	public updateLoginFormState(key:string, value:string) : void
	{
		const updatedState = { ...this.state };
		updatedState.email.error = null;
		updatedState.password.error = null;
		
		switch (key)
		{
			case 'emailAddress':
				updatedState.email.value = value;
				break;
			case 'password':
				updatedState.password.value = value;
				break;
		}
		this.setState(updatedState);
	}

	render()
	{
		return (
			<form onSubmit={ this.handleLoginSubmit } noValidate className="login-form">
				<OutlineInput ref={ this._emailInput } error={ this.state.email.error } type="email" required={ true } label="Email Address" name="emailAddress" form={ this.updateLoginFormState.bind(this) } />
				<OutlineInput ref={ this._passwordInput } error={ this.state.password.error } type="password" required={ true } label="Password" name="password"  form={ this.updateLoginFormState.bind(this) } />
				<div className="-space-between">
					<Link to="/forgotten-password">Forgotten password</Link>
					<Button label="Log in" type="Submit" classes="-solid -primary" />
				</div>
			</form>
		);
	}
}

import React, { FunctionComponent } from 'react';

/** CSS */
import './button.scss';

type ButtonProps = {
	label: string,
	type?: string,
	classes: string
}

export const Button:FunctionComponent<ButtonProps> = (props:ButtonProps) => (
	<button className={ `g-button ${ props.classes }` } type={ props.type }>{ props.label }</button>
);

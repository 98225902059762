/** NPM Packages */
import React, { Component, MouseEventHandler } from 'react';

/** CSS */
import './user-data-table.scss';

/** Components */
import { ActivityListItem } from './activity-list-item/activity-list-item';
import { CheckboxInput } from '../../../../checkbox-input/checkbox-input';

interface UserDataTableProps
{
	activity: Array<SelectedListItem>|null,
	page: number,
	total: number,
	loadNextPage: ()=>void,
	loadPrevPage: ()=>void,
	updateSelectedActivityList: (activity:Activity, status:boolean)=>void
	changeSelectAllState: ()=>void,
	selectAllStatus: boolean,
}

interface UserDataTableState
{
	
}

export class UserDataTable extends Component<UserDataTableProps, UserDataTableState>
{
	private handleNextPageClickEvent:MouseEventHandler<HTMLButtonElement> = this.props.loadNextPage;
	private handlePrevPageClickEvent:MouseEventHandler<HTMLButtonElement> = this.props.loadPrevPage;

	private renderTableRow = (activity:SelectedListItem, i:number) => (
		<ActivityListItem activity={ activity } key={ i } id={ activity.uid } updateSelectedItemsList={ this.props.updateSelectedActivityList } />
	);

	private renderTableLoader = () => (
		<div>
			LOADING
		</div>
	);

	render()
	{
		let rows:JSX.Element|JSX.Element[] = this.renderTableLoader();

		if (this.props.activity)
		{
			rows = this.props.activity.map((activity, i) => this.renderTableRow(activity, i));
		}

		return (
			<div className="user-data-table">
				<div className="-header">
					<div className="-header-shim">
						<span>
							<CheckboxInput name="selectAllActivity" label={ null } changeState={ this.props.changeSelectAllState } isChecked={ this.props.selectAllStatus } />
						</span>
						<span>Type</span>
						<span>Title</span>
						<span>Date</span>
						<span>Preview</span>
					</div>
				</div>
				<ul>
					{ rows }
				</ul>
				<div className="-footer">
					<div>
						<span>{ `${ (this.props.page * 10 + 1) } - ${ this.props.page * 10 + 10 } of ${ this.props.total }` }</span>
					</div>
					<div>
						<button onClick={ this.handlePrevPageClickEvent }>
							<svg xmlns="http://www.w3.org/2000/svg" width="9.531" height="16" viewBox="0 0 9.531 16">
								<path id="angle-left-regular" d="M.851,103.55l7.339-7.4a.6.6,0,0,1,.85,0l.99.99a.6.6,0,0,1,0,.85l-5.924,5.984,5.924,5.984a.6.6,0,0,1,0,.85l-.99.99a.6.6,0,0,1-.85,0L.851,104.4A.6.6,0,0,1,.851,103.55Z" transform="translate(-0.675 -95.975)" fill="currentColor" />
							</svg>
						</button>
						<button onClick={ this.handleNextPageClickEvent }>
							<svg xmlns="http://www.w3.org/2000/svg" width="9.531" height="16" viewBox="0 0 9.531 16">
								<path id="angle-right-regular" d="M10.03,104.4l-7.339,7.4a.6.6,0,0,1-.85,0l-.99-.99a.6.6,0,0,1,0-.85l5.924-5.984L.851,97.991a.6.6,0,0,1,0-.85l.99-.99a.6.6,0,0,1,.85,0l7.339,7.4A.6.6,0,0,1,10.03,104.4Z" transform="translate(-0.675 -95.975)" fill="currentColor" />
							</svg>
						</button>
					</div>
				</div>
			</div>
		);
	}
}

/** NPM Packages */
import React, { Component } from "react";

/** CSS */
import './login-view.scss';

/** Components */
import { Footer } from '../footer/footer';
import { LoginForm } from './login-form/login-form';
import { Link } from "react-router-dom";

export class LoginView extends Component
{
	render()
	{
		return (
			<main data-view="login">
				<div>
					<h1>ReFyne Parent Portal</h1>
					<LoginForm context={ this.props } />
					<div className="signup-cta">
						<span>Don’t have an account?</span>
						<Link to="/register">Sign Up.</Link>
					</div>
				</div>
				<Footer />
			</main>
		);
	}
}

/** NPM Packages */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './reset-email-sent-view.scss';

/** Components */
import { Footer } from '../footer/footer';

type ResetEmailSentViewProps = {
	
}

type ResetEmailSentViewState = {
	
}

export class ResetEmailSentView extends Component<ResetEmailSentViewProps, ResetEmailSentViewState>
{
	render()
	{
		return (
			<main data-view="reset-email-sent">
				<div>
					<h1>Email Sent</h1>
					<p>An email has been sent to your inbox with a reset link. Please click the link to continue with the password resetting process.</p>
					<Link to="/">Return to Login Screen</Link>
				</div>
				<Footer />
			</main>
		);
	}
}

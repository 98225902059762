/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './forgotten-password-view.scss';

/** Components */
import { ForgottenPasswordForm } from './forgotten-password-form/forgotten-password-form';
import { Footer } from '../footer/footer';

type ForgottenPasswordViewProps = {
	
}

type ForgottenPasswordViewState = {
	
}

export class ForgottenPasswordView extends Component<ForgottenPasswordViewProps, ForgottenPasswordViewState>
{
	render()
	{
		return (
			<main data-view="forgotten-password">
				<ForgottenPasswordForm context={ this.props } />
				<Footer />
			</main>
		);
	}
}

/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './user-data-view.scss';

/** Components */
import { DataManagerUserSwitcher } from './data-manager-user-switcher/data-manager-user-switcher';
import { UserDataManager } from './user-data-manager/user-data-manager';

interface UserDataViewProps
{
	userId: string,
	children: Array<Child>,
	history: any,
}

interface UserDataViewState
{
	
}

export class UserDataView extends Component<UserDataViewProps, UserDataViewState>
{
	componentWillMount()
	{
		let validChild = false;

		for (let i = 0; i < this.props.children.length; i++)
		{
			if (this.props.children[i].uid === this.props.userId)
			{
				validChild = true;
				break;
			}
		}

		if (!validChild)
		{
			this.props.history.push('/dashboard/data');
		}
	}

	public changeSelectedChild(uid:string) : void
	{
		this.props.history.push(`/dashboard/data/${ uid }`);
	}

	render()
	{
		return (
			<div className="user-data-view">
				<h1>
					Data Management for
					<DataManagerUserSwitcher children={ this.props.children } selectedChildUid={ this.props.userId } changeSelectedChild={ this.changeSelectedChild.bind(this) } />
					<UserDataManager userId={ this.props.userId } />
				</h1>
			</div>
		);
	}
}

/** NPM Packages */
import React, { Component, MouseEventHandler } from 'react';

/** CSS */
import './header-user-controller.scss';
import { Link } from 'react-router-dom';

interface HeaderUserControllerProps
{
	user: User
}

interface HeaderUserControllerState
{
	modalOpen: boolean,
}

export class HeaderUserController extends Component<HeaderUserControllerProps, HeaderUserControllerState>
{
	constructor(props:HeaderUserControllerProps)
	{
		super(props);
		this.state = {
			modalOpen: false
		};
	}

	private handleModalButtonClickEvent:any = this.toggleModal.bind(this);
	private handleLogoutClickEvent:MouseEventHandler = this.logout.bind(this);

	private logout() : void
	{
		console.warn('User logout action has not been implemented yet');
	}

	private toggleModal(e:MouseEvent) : void
	{
		e.stopPropagation();
		const updatedState = { ...this.state };
		
		if (!this.state.modalOpen)
		{
			updatedState.modalOpen = true;
			this.setState(updatedState);
		}
		else
		{
			updatedState.modalOpen = false;
			this.setState(updatedState);
		}
	}

	private modal = (firstNameLetter:string) => (
		<div className="-modal">
			<div className="-user-details">
				<div className="-user-image">
					<span>{ firstNameLetter }</span>
				</div>
				<div className="-user-info">
					<span>{ this.props.user.firstName } { this.props.user.lastName }</span>
					<span>{ this.props.user.email }</span>
				</div>
			</div>
			<nav>
				<Link to="/dashboard/home">
					<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
						<g id="icon" transform="translate(-1 -1)">
							<rect id="Rectangle_4" data-name="Rectangle 4" width="6" height="6" transform="translate(1 1)" fill="currentColor"/>
							<rect id="Rectangle_5" data-name="Rectangle 5" width="6" height="6" transform="translate(9 1)" fill="currentColor"/>
							<rect id="Rectangle_6" data-name="Rectangle 6" width="6" height="6" transform="translate(1 9)" fill="currentColor"/>
							<rect id="Rectangle_7" data-name="Rectangle 7" width="6" height="6" transform="translate(9 9)" fill="currentColor"/>
						</g>
					</svg>
					Home
				</Link>
				<Link className="-mobile" to="/dashboard/data">
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-chart-line" className="svg-inline--fa fa-file-chart-line fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-96 299.2c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-70.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v70.4zm96 0c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8V236.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v198.4zm32-134.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v134.4c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8V300.8z"></path></svg>
					Data Management
				</Link>
				<Link className="-mobile"  to="/dashboard/family">
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-friends" className="svg-inline--fa fa-user-friends fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"></path></svg>
					Family Management
				</Link>
				<Link className="-mobile"  to="/dashboard/help">
					<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
					Help
				</Link>
				<Link className="-mobile"  to="/dashboard/support">
					<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="life-ring" className="svg-inline--fa fa-life-ring fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 504c136.967 0 248-111.033 248-248S392.967 8 256 8 8 119.033 8 256s111.033 248 248 248zm-103.398-76.72l53.411-53.411c31.806 13.506 68.128 13.522 99.974 0l53.411 53.411c-63.217 38.319-143.579 38.319-206.796 0zM336 256c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zm91.28 103.398l-53.411-53.411c13.505-31.806 13.522-68.128 0-99.974l53.411-53.411c38.319 63.217 38.319 143.579 0 206.796zM359.397 84.72l-53.411 53.411c-31.806-13.505-68.128-13.522-99.973 0L152.602 84.72c63.217-38.319 143.579-38.319 206.795 0zM84.72 152.602l53.411 53.411c-13.506 31.806-13.522 68.128 0 99.974L84.72 359.398c-38.319-63.217-38.319-143.579 0-206.796z"></path></svg>
					Contact Support
				</Link>
				<Link to="/dashboard/account">
					<svg xmlns="http://www.w3.org/2000/svg" width="12.25" height="14" viewBox="0 0 12.25 14">
						<path id="user-solid" d="M6.125,7a3.5,3.5,0,1,0-3.5-3.5A3.5,3.5,0,0,0,6.125,7Zm2.45.875H8.118a4.76,4.76,0,0,1-3.987,0H3.675A3.676,3.676,0,0,0,0,11.55v1.137A1.313,1.313,0,0,0,1.313,14h9.625a1.313,1.313,0,0,0,1.313-1.312V11.55A3.676,3.676,0,0,0,8.575,7.875Z" fill="currentColor" />
					</svg>
					My Account
				</Link>
				<Link to="/">
					<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10.499" viewBox="0 0 14 10.499">
						<path id="sign-out-solid" d="M4.922,74.5h-2.3A2.626,2.626,0,0,1,0,71.875v-5.25A2.626,2.626,0,0,1,2.625,64h2.3a.329.329,0,0,1,.328.328v1.094a.329.329,0,0,1-.328.328h-2.3a.874.874,0,0,0-.875.875v5.25a.874.874,0,0,0,.875.875h2.3a.329.329,0,0,1,.328.328v1.094A.329.329,0,0,1,4.922,74.5Zm3.224-8.287,2.122,1.944H5.031a.655.655,0,0,0-.656.656v.875a.655.655,0,0,0,.656.656h5.236L8.145,72.287a.658.658,0,0,0-.022.949l.6.6a.658.658,0,0,0,.927,0l4.156-4.123a.655.655,0,0,0,0-.932L9.652,64.664a.656.656,0,0,0-.927,0l-.6.6a.655.655,0,0,0,.019.946Z" transform="translate(0 -64)" fill="currentColor"/>
					</svg>
					Logout
				</Link>
				{/* <button onClick={ this.handleLogoutClickEvent }>
					<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10.499" viewBox="0 0 14 10.499">
						<path id="sign-out-solid" d="M4.922,74.5h-2.3A2.626,2.626,0,0,1,0,71.875v-5.25A2.626,2.626,0,0,1,2.625,64h2.3a.329.329,0,0,1,.328.328v1.094a.329.329,0,0,1-.328.328h-2.3a.874.874,0,0,0-.875.875v5.25a.874.874,0,0,0,.875.875h2.3a.329.329,0,0,1,.328.328v1.094A.329.329,0,0,1,4.922,74.5Zm3.224-8.287,2.122,1.944H5.031a.655.655,0,0,0-.656.656v.875a.655.655,0,0,0,.656.656h5.236L8.145,72.287a.658.658,0,0,0-.022.949l.6.6a.658.658,0,0,0,.927,0l4.156-4.123a.655.655,0,0,0,0-.932L9.652,64.664a.656.656,0,0,0-.927,0l-.6.6a.655.655,0,0,0,.019.946Z" transform="translate(0 -64)" fill="currentColor"/>
					</svg>
					Logout
				</button> */}
			</nav>
		</div>
	);

	render()
	{
		let firstNameLetter = '?';
		const letters = this.props.user.firstName.match(/\w/gi);
		if (letters)
		{
			firstNameLetter = letters[0];
		}

		let modal = null;

		if (this.state.modalOpen)
		{
			modal = this.modal(firstNameLetter);
		}

		return (
			<div className="header-user-controller">
				<button onClick={ this.handleModalButtonClickEvent }>
					<div className="-user-image">
						<span>{ firstNameLetter }</span>
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" width="13" height="7.889" viewBox="0 0 13 7.889">
  						<path id="chevron-down-solid" d="M11.651,131.175l-5.786-5.786a.714.714,0,0,1,0-1.01l.675-.675a.714.714,0,0,1,1.009,0l4.607,4.585,4.607-4.585a.714.714,0,0,1,1.009,0l.675.675a.714.714,0,0,1,0,1.01l-5.785,5.786a.715.715,0,0,1-1.01,0Z" transform="translate(-5.656 -123.494)" fill="currentColor" />
					</svg>
				</button>
				{ modal }
			</div>
		);
	}
}

/** NPM Packages */
import React, { Component, Fragment } from 'react';

/** CSS */
import './register-view.scss';

/** Components */
import { Footer } from '../footer/footer';
import { SignupForm } from './signup-form/signup-form';
import { ChildrenForm } from './children-form/children-form';
import { ChildLoginForm } from './child-login-form/child-login-form';
import { AddChildAccountForm } from './add-child-account-form/add-child-account-form';
import { PrivacyPolicyForm } from './privacy-policy-form/privacy-policy-form';
import { TermsOfUseForm } from './terms-of-use-form/terms-of-use-form';
import { COPAForm } from './copa-form/copa-form';

type RegisterViewProps = {
	view: string,
	match: { params: { slug: string } },
	history: any,
}

type RegisterViewState = {
	account: ParentAccount|null,
	children: Array<Child>,
	policies: { privacy: boolean, termsOfUse: boolean, copa: boolean }
}

export class RegisterView extends Component<RegisterViewProps, RegisterViewState>
{
	constructor(props:RegisterViewProps)
	{
		super(props);
		this.state = {
			account: null,
			children: [],
			policies: {
				privacy: false,
				termsOfUse: false,
				copa: false,
			},
		};
	}

	public updateAccount(updatedAccount:ParentAccount, moveForward:boolean = true) : void
	{
		let updatedState = { ...this.state };
		updatedState.account = updatedAccount;
		sessionStorage.setItem('accountDetails', JSON.stringify(updatedState.account));
		this.setState(updatedState);
		
		if (moveForward)
		{
			this.props.history.push('/register/children');
		}
	}

	public linkChildAccount(childAccount:Child) : void
	{
		let updatedState = { ...this.state };
		updatedState.children.push(childAccount);
		sessionStorage.setItem('children', JSON.stringify(updatedState.children));
		this.setState(updatedState);
		this.props.history.push('/register/children');
	}

	public removeChild(username:string) : void
	{
		for (let i = 0; i < this.state.children.length; i++)
		{
			if (this.state.children[i].username === username)
			{
				let updatedState = { ...this.state };
				updatedState.children.splice(i, 1);
				sessionStorage.setItem('children', JSON.stringify(updatedState.children));
				this.setState(updatedState);
				break;
			}
		}
	}

	public addChildAccount(child:Child) : void
	{
		let updatedState = { ...this.state };
		updatedState.children.push(child);
		sessionStorage.setItem('children', JSON.stringify(updatedState.children));
		this.setState(updatedState);
		this.props.history.push('/register/children');
	}

	public privacyPolicyAccepted() : void
	{
		const updatedState = { ...this.state };
		updatedState.policies.privacy = true;
		this.setState(updatedState);
		this.props.history.push('/register/terms-of-use');
	}

	public termsOfUseAccepted() : void
	{
		const updatedState = { ...this.state };
		updatedState.policies.termsOfUse = true;
		this.setState(updatedState);
		this.props.history.push('/register/copa');
	}

	public copaAccepted() : void
	{
		const updatedState = { ...this.state };
		updatedState.policies.copa = true;
		this.setState(updatedState);
		this.props.history.push('/dashboard');
	}

	private signup = () => (
		<SignupForm updateAccount={ this.updateAccount.bind(this) } />
	);

	private addingChildren = () => (
		<ChildrenForm children={ this.state.children } removeChild={ this.removeChild.bind(this) } />
	);

	private linkAccount = () => (
		<ChildLoginForm linkChildAccount={ this.linkChildAccount.bind(this) } />
	);

	private addAccount = () => (
		<AddChildAccountForm addChildAccount={ this.addChildAccount.bind(this) } />
	);

	private privacyPolicy = () => (
		<PrivacyPolicyForm acceptPolicy={ this.privacyPolicyAccepted.bind(this) } />
	);

	private termsOfUse = () => (
		<TermsOfUseForm acceptPolicy={ this.termsOfUseAccepted.bind(this) } />
	);

	private copa = () => (
		<COPAForm acceptPolicy={ this.copaAccepted.bind(this) } />
	);

	componentWillMount()
	{
		const accountDetails = sessionStorage.getItem('accountDetails');

		if (accountDetails)
		{
			this.updateAccount(JSON.parse(accountDetails), false);
		}

		let existingChildren = sessionStorage.getItem('children');

		if (existingChildren)
		{
			const children:Array<Child> = JSON.parse(existingChildren);
			
			for (let i = 0; i < children.length; i++)
			{
				this.linkChildAccount(children[i]);
			}
		}

		if (this.props.match.params.slug !== 'signup' && !accountDetails)
		{
			this.props.history.push('/register/signup');
		}
	}

	render()
	{
		let view = null;
		switch (this.props.match.params.slug)
		{
			case 'signup':
				view = this.signup();
				break;
			case 'children':
				view = this.addingChildren();
				break;
			case 'link-account':
				view = this.linkAccount();
				break;
			case 'add-account':
				view = this.addAccount();
				break;
			case 'privacy':
				view = this.privacyPolicy();
				break;
			case 'terms-of-use':
				view = this.termsOfUse();
				break;
			case 'copa':
				view = this.copa();
				break;
		}
		return (
			<Fragment>
				<main data-view="register">
					{ view }
				</main>
				<Footer />
			</Fragment>
		);
	}
}

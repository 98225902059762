/** NPM Packages */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './children-form.scss';

/** Components */
import { ChildAccount } from './child-account/child-account';

interface ChildrenFormProps
{
	children: Array<Child>,
	removeChild: (username:string)=>void,
}

interface ChildrenFormState
{
	children: Array<Child>,
}

export class ChildrenForm extends Component<ChildrenFormProps, ChildrenFormState>
{
	private displayChildren = (child:Child) => (
		<ChildAccount username={ child.username } remove={ this.props.removeChild.bind(this) } />
	);

	render()
	{
		let children = this.props.children.map(child => this.displayChildren(child));

		return (
			<div className="children-form">
				<h1>Adding Child Accounts</h1>
				<p>Please link your child’s existing account or help your child sign up for a new account.</p>
				<div className="-two-cols">
					<Link to="/register/link-account">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  							<path id="link-regular" d="M14.729,9.271a6.571,6.571,0,0,1,.457,8.773c-.293.362-.13.181-3.982,4.032A6.563,6.563,0,0,1,1.924,12.8C3.46,11.259,4.354,10.362,4.9,9.821a.562.562,0,0,1,.96.384,8.124,8.124,0,0,0,.222,1.773.559.559,0,0,1-.15.525L3.78,14.651A3.938,3.938,0,0,0,9.348,20.22L12.873,16.7A3.939,3.939,0,0,0,11.8,10.366a.564.564,0,0,1-.308-.579,2.424,2.424,0,0,1,.694-1.425l.205-.205a.559.559,0,0,1,.645-.108,6.525,6.525,0,0,1,1.689,1.221Zm7.347-7.347a6.559,6.559,0,0,0-9.281,0C8.944,5.774,9.107,5.593,8.814,5.956A6.563,6.563,0,0,0,10.96,15.95a.559.559,0,0,0,.645-.108l.205-.205a2.424,2.424,0,0,0,.694-1.425.564.564,0,0,0-.308-.579A3.939,3.939,0,0,1,11.127,7.3l3.525-3.525A3.938,3.938,0,0,1,20.22,9.348L18.071,11.5a.559.559,0,0,0-.15.525,8.124,8.124,0,0,1,.222,1.773.562.562,0,0,0,.96.384c.543-.542,1.436-1.438,2.972-2.975a6.558,6.558,0,0,0,0-9.281Z" transform="translate(0 0)" fill="currentColor"/>
						</svg>
						Link Existing Child Account
					</Link>
					<Link to="/register/add-account">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.2" viewBox="0 0 24 19.2">
  							<path id="user-plus-regular" d="M8.4,10.8A5.4,5.4,0,1,0,3,5.4,5.4,5.4,0,0,0,8.4,10.8Zm0-9A3.6,3.6,0,1,1,4.8,5.4,3.606,3.606,0,0,1,8.4,1.8Zm3.36,9.6c-1.076,0-1.594.6-3.36.6s-2.28-.6-3.36-.6A5.041,5.041,0,0,0,0,16.44v.96a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8v-.96A5.041,5.041,0,0,0,11.76,11.4Zm3.24,6H1.8v-.96A3.246,3.246,0,0,1,5.04,13.2a14.682,14.682,0,0,0,3.36.6,14.541,14.541,0,0,0,3.36-.6A3.246,3.246,0,0,1,15,16.44Zm8.4-9.3H20.7V5.4a.6.6,0,0,0-.6-.6h-.6a.6.6,0,0,0-.6.6V8.1H16.2a.6.6,0,0,0-.6.6v.6a.6.6,0,0,0,.6.6h2.7v2.7a.6.6,0,0,0,.6.6h.6a.6.6,0,0,0,.6-.6V9.9h2.7a.6.6,0,0,0,.6-.6V8.7A.6.6,0,0,0,23.4,8.1Z" fill="currentColor"/>
						</svg>
						Add a New Child Account
					</Link>
				</div>
				<h1 className={ (this.props.children.length) ? '' : 'is-hidden' }>Child Accounts</h1>
				<div className={ (this.props.children.length) ? '-children-accounts' : '-no-accounts' }>
					{ children }
				</div>
				<div className="-flex-end">
					<Link to="/register/signup" className="g-button -outline -grey">Back</Link>
					<Link to="/register/privacy" className={`g-button -solid -primary ${ (this.props.children.length) ? '' : 'is-hidden' }`}>Next</Link>
				</div>
			</div>
		);
	}
}

/** NPM Packages */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './activity-overview.scss';

/** Components */
import { ActivityTable } from './activity-table/activity-table';

interface ActivityOverviewProps
{
	children: Array<Child>,
}

interface ActivityOverviewState
{
	
}

export class ActivityOverview extends Component<ActivityOverviewProps, ActivityOverviewState>
{
	private renderChild = (child:Child, i:number) => (
		<div className="-child" key={ i }>
			<header>{ child.firstName } Recent Activity</header>
			<ActivityTable child={ (child.uid) ? child.uid : '' } />
			<div className="-flex-start">
				<Link to={ `/dashboard/data/${ child.uid }` } className="g-button -outline -grey">View All</Link>
			</div>
		</div>
	);

	render()
	{
		const children = this.props.children.map((child, i) => this.renderChild(child, i));
		return (
			<div className="activity-overview">
				{ children }
			</div>
		);
	}
}

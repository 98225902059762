/** NPM Packages */
import React, { Component, MouseEventHandler } from 'react';
import Modal from 'react-modal';

/** CSS */
import './confirmation-modal.scss';

interface ConfirmationModalProps
{
	title: string,
	body: string,
	confirmCallback: ()=>void,
	closeModal: ()=>void,
}

interface ConfirmationModalState
{
	
}

export class ConfirmationModal extends Component<ConfirmationModalProps, ConfirmationModalState>
{

	private handleModalCloseClickEvent:MouseEventHandler<HTMLButtonElement> = this.props.closeModal;

	componentWillMount()
	{
		const appShell:HTMLElement|null = document.body.querySelector('app-shell');

		if (appShell)
		{
			Modal.setAppElement(appShell);
		}
	}

	render()
	{
		return (
			<Modal isOpen={ true } onRequestClose={ this.props.closeModal } className="confirmation-modal" overlayClassName="confirmation-modal-backdrop" >
				<header>{ this.props.title }</header>
				<p>{ this.props.body }</p>
				<div className="-flex-end">
					<button onClick={ this.handleModalCloseClickEvent } className="g-button -text -grey">Close</button>
					<button onClick={ this.props.confirmCallback } className="g-button -text -danger">Delete</button>
				</div>
			</Modal>
		);
	}
}

/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './activity-table.scss';

/** Components */
import { ActivityTableLineItem } from './activity-table-line-item/activity-table-line-item';

interface ActivityTableProps
{
	child: string,
}

interface ActivityTableState
{
	activity: Array<Activity>,
}

export class ActivityTable extends Component<ActivityTableProps, ActivityTableState>
{
	constructor(props:ActivityTableProps)
	{
		super(props);
		this.state = {
			activity: []
		}
	}

	componentDidMount()
	{
		console.warn('Fetching child activity from live API is not implemented yet');

		fetch(`${ window.location.origin }/api/${ this.props.child }.json`)
		.then(request => request.json())
		.then(response => {
			const updatedState = { ...this.state };
			updatedState.activity = response.activity;
			this.setState(updatedState);
		})
		.catch(error => {
			console.error('Failed to fetch child activity', error);
		});
	}

	private renderActivityItem = (activityItem:Activity, i:number) => (
		<ActivityTableLineItem key={ i } contentId={ activityItem.contentId } type={ activityItem.type } contentTitle={ activityItem.contentTitle } contentType={ activityItem.contentType } />
	);

	render()
	{
		const activityList = this.state.activity.map((activityItem, i) => this.renderActivityItem(activityItem, i));
		return (
			<ul className="activity-table">
				{ activityList }
			</ul>
		);
	}
}

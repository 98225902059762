/** NPM Packages */
import React, { Component, ChangeEventHandler, SyntheticEvent, createRef, RefObject } from 'react';

/** CSS */
import './data-manager-user-switcher.scss';

interface DataManagerUserSwitcherProps
{
	children: Array<Child>,
	selectedChildUid: string,
	changeSelectedChild: (uid:string)=>void,
}

interface DataManagerUserSwitcherState
{
	
}

export class DataManagerUserSwitcher extends Component<DataManagerUserSwitcherProps, DataManagerUserSwitcherState>
{
	public input : RefObject<HTMLSelectElement>;

	constructor(props:DataManagerUserSwitcherProps)
	{
		super(props);
		this.input = createRef<HTMLSelectElement>();
	}

	private handleSelectChangeEvent:ChangeEventHandler<HTMLSelectElement> = this.switchUser.bind(this);

	private switchUser(e:SyntheticEvent)
	{
		if (!this.input.current)
		{
			return;
		}

		this.props.changeSelectedChild(this.input.current.value);
	}

	private renderChildOption = (child:Child, i:number) => (
		<option key={ i } value={ child.uid }>{ child.firstName }</option>
	);

	componentDidMount()
	{
		if (!this.input.current)
		{
			return;
		}

		this.input.current.value = this.props.selectedChildUid;
	}

	render()
	{
		const options = this.props.children.map((child, i) => this.renderChildOption(child, i));
		return (
			<div className="data-manager-user-switcher">
				<select ref={ this.input } onChange={ this.handleSelectChangeEvent }>
					{ options }
				</select>
				<svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7.006">
  					<path id="sort-down-solid" d="M17.944,288h9.981a1.008,1.008,0,0,1,.713,1.719l-4.991,4.991a1,1,0,0,1-1.422,0l-4.995-4.991A1.008,1.008,0,0,1,17.944,288Z" transform="translate(-16.934 -288)" fill="currentColor" />
				</svg>
			</div>
		);
	}
}

/** NPM Packages */
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

/** Components */
import { LoginView } from './login/login-view';
import { DashboardView } from './dashboard/dashboard-view';
import { NotFoundView } from './not-found/not-found-view';
import { ForgottenPasswordView } from './forgotten-password-view/forgotten-password-view';
import { ResetEmailSentView } from './reset-email-sent-view/reset-email-sent-view';
import { RegisterView } from './register-view/register-view';

export const Router = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={ LoginView } />
			<Redirect exact path="/dashboard" to="/dashboard/home" />
			<Route path="/dashboard/:slug/:uid" component={ DashboardView } />
			<Route path="/dashboard/:slug" component={ DashboardView } />
			<Route exact path="/forgotten-password" component={ ForgottenPasswordView } />
			<Route exact path="/forgotten-password/sent" component={ ResetEmailSentView } />
			<Redirect exact path="/register" to="/register/signup" />
			<Route path="/register/:slug" component={ RegisterView } />
			<Route component={ NotFoundView } />
		</Switch>
	</BrowserRouter>
);

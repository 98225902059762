/** NPM Packages */
import React, { Component, MouseEventHandler, Fragment } from 'react';

/** CSS */
import './activity-table-line-item.scss';

/** Components */
import { ContentModal } from '../../../../../content-modal/content-modal';

interface ActivityTableLineItemProps
{
	contentId: string,
	type: string,
	contentTitle: string,
	contentType: string,
}

interface ActivityTableLineItemState
{
	modalIsOpen: boolean,
}

export class ActivityTableLineItem extends Component<ActivityTableLineItemProps, ActivityTableLineItemState>
{
	constructor(props:ActivityTableLineItemProps)
	{
		super(props);
		this.state = {
			modalIsOpen: false,
		}
	}

	private handleLineItemClick:MouseEventHandler<HTMLLIElement> = this.openModal.bind(this);

	private openModal() : void
	{
		const updatedState = { ...this.state };
		updatedState.modalIsOpen = true;
		this.setState(updatedState);
	}

	private closeModal() : void
	{
		const updatedState = { ...this.state };
		updatedState.modalIsOpen = false;
		this.setState(updatedState);
	}

	private renderIcon(type:string)
	{
		let icon = null;

		switch (type.toLowerCase())
		{
			case 'like':
				icon = (
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
						<g id="Icon" transform="translate(-690 -420)">
							<path id="thumbs-up-regular" d="M21.856,13.439A4.433,4.433,0,0,0,22.5,11.1a4.079,4.079,0,0,0-4.023-4.012h-1.71a5.985,5.985,0,0,0,.415-2.182C17.182,1.5,15.415,0,12.716,0,9.828,0,9.993,4.45,9.352,5.091,8.286,6.157,7.027,8.205,6.129,9H1.5A1.5,1.5,0,0,0,0,10.5V21.75a1.5,1.5,0,0,0,1.5,1.5h3a1.5,1.5,0,0,0,1.452-1.123C8.038,22.174,9.471,24,14.287,24h1.042c3.615,0,5.249-1.848,5.294-4.469a4.519,4.519,0,0,0,.813-3.14A4.353,4.353,0,0,0,21.856,13.439Zm-2.895,2.523a2.081,2.081,0,0,1-.653,2.7c.361,2.287-.825,3.089-2.49,3.089H14.046c-3.358,0-5.533-1.773-8.046-1.773V11.25h.512c1.329,0,3.187-3.323,4.432-4.568,1.329-1.329.886-3.545,1.773-4.432,2.216,0,2.216,1.546,2.216,2.659,0,1.836-1.329,2.659-1.329,4.432h4.875a1.831,1.831,0,0,1,1.773,1.773c0,.886-.6,1.772-1.044,1.772a2.252,2.252,0,0,1-.244,3.076ZM4.125,20.25A1.125,1.125,0,1,1,3,19.125,1.125,1.125,0,0,1,4.125,20.25Z" transform="translate(691 420)" fill="currentColor" />
						</g>
					</svg>
				);
				break;
			case 'view':
				icon = (
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  						<g id="Icon" transform="translate(-690 -420)">
    						<path id="eye-regular" d="M12,67.333a4.623,4.623,0,0,0-1.3.208,2.308,2.308,0,0,1,.3,1.125A2.333,2.333,0,0,1,8.667,71a2.308,2.308,0,0,1-1.125-.3A4.655,4.655,0,1,0,12,67.333Zm11.855,4.058A13.364,13.364,0,0,0,12,64,13.366,13.366,0,0,0,.146,71.392a1.348,1.348,0,0,0,0,1.216A13.364,13.364,0,0,0,12,80a13.366,13.366,0,0,0,11.855-7.392,1.348,1.348,0,0,0,0-1.216ZM12,78a11.335,11.335,0,0,1-9.914-6A11.334,11.334,0,0,1,12,66a11.334,11.334,0,0,1,9.914,6A11.334,11.334,0,0,1,12,78Z" transform="translate(689.999 360)" fill="currentColor" />
  						</g>
					</svg>
				);
				break;
			case 'poll':
				icon = (
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
					  	<g id="Icon" transform="translate(-690 -420)">
					  	  	<path id="poll-regular" d="M21.429,32H2.571A2.572,2.572,0,0,0,0,34.571V53.429A2.572,2.572,0,0,0,2.571,56H21.429A2.572,2.572,0,0,0,24,53.429V34.571A2.572,2.572,0,0,0,21.429,32Zm0,21.429H2.571V34.571H21.429Zm-15-2.571h.857A.857.857,0,0,0,8.143,50V43.143a.857.857,0,0,0-.857-.857H6.429a.857.857,0,0,0-.857.857V50A.857.857,0,0,0,6.429,50.857Zm5.143,0h.857A.857.857,0,0,0,13.286,50V38a.857.857,0,0,0-.857-.857h-.857a.857.857,0,0,0-.857.857V50A.857.857,0,0,0,11.571,50.857Zm5.143,0h.857A.857.857,0,0,0,18.429,50V46.571a.857.857,0,0,0-.857-.857h-.857a.857.857,0,0,0-.857.857V50A.857.857,0,0,0,16.714,50.857Z" transform="translate(690 388)" fill="currentColor"/>
					  	</g>
					</svg>
				);
				break;
		}

		return icon;
	}

	render()
	{
		return (
			<Fragment>
				<li className="activity-table-line-item" onClick={ this.handleLineItemClick }>
					{ this.renderIcon(this.props.type) } { this.props.contentTitle }
				</li>
				<ContentModal closeModal={ this.closeModal.bind(this) } modalIsOpen={ this.state.modalIsOpen } contentId={ this.props.contentId } contentType={ this.props.contentType } contentTitle={ this.props.contentTitle } />
			</Fragment>
		);
	}
}

/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './article-content.scss';

interface ArticleContentProps
{
	contentId: string,
}

interface ArticleContentState
{
	content: Article|null,
}

export class ArticleContent extends Component<ArticleContentProps, ArticleContentState>
{
	constructor(props:ArticleContentProps)
	{
		super(props);
		this.state = {
			content: null,
		}
	}

	componentWillMount()
	{
		console.warn('Fetching article content details is not implemented yet');

		setTimeout(() => {
			const updatedState = { ...this.state };
			updatedState.content = {
				title: 'You\'r not unmotivated, lazy, or bored',
				subtitle: 'And other lies you tell yourself',
				body: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><img src="https://images.unsplash.com/photo-1568259143690-4bace7f97744?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=4480&q=80" alt="I hope you know how loved you are written in pink on a white wall" /><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>'
			};
			this.setState(updatedState);
		}, 3000);
	}

	render()
	{
		if (this.state.content)
		{
			return (
				<div className="article-content" dangerouslySetInnerHTML={{ __html: this.state.content.body }}>
				</div>
			);
		}
		
		return (
			<div className="article-content">
				<div className="-circle-loader">
            		<div className="-circle"></div>
        		</div>
			</div>
		);
	}
}

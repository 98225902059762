/** NPM Packages */
import React, { Component, FormEvent, FormEventHandler } from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './signup-form.scss';
import '../../form-grid.scss';

/** Components */
import { OutlineInput } from '../../outline-input/outline-input';
import { Button } from '../../button/button';

type SignupFormProps = {
	updateAccount: (accountDetails:ParentAccount)=>void;
}

type SignupFormState = {
	firstName: {
		value: string,
		error: string|null,
	},
	lastName: {
		value: string,
		error: string|null,
	},
	email: {
		value: string,
		error: string|null,
	},
	password: {
		value: string,
		error: string|null,
	},
	confirmPassword: {
		value: string,
		error: string|null,
	},
}

export class SignupForm extends Component<SignupFormProps, SignupFormState>
{
	constructor(props:SignupFormProps)
	{
		super(props);
		this.state = {
			firstName: {
				value: '',
				error: null,
			},
			lastName: {
				value: '',
				error: null,
			},
			email: {
				value: '',
				error: null,
			},
			password: {
				value: '',
				error: null,
			},
			confirmPassword: {
				value: '',
				error: null,
			},
		};
	}

	private handleSubmitEvent:FormEventHandler = this.submit.bind(this);

	private verifyFormInputs() : Promise<any>
	{
		return new Promise((resolve, reject)=>{
			if(this.state.firstName.value === '')
			{
				reject({ input: 'firstName', error: 'Please enter your first name.' });
			}
			else if(this.state.lastName.value === '')
			{
				reject({ input: 'lastName', error: 'Please enter your last name.' });
			}
			else if (!this.state.email.value.match(/^\S+@\S+\.\S+$/g))
			{
				reject({ input: 'email', error: 'Please provide a valid email address.' });
			}
			else if (this.state.password.value === '')
			{
				reject({ input: 'password', error: 'Please enter a password.' });
			}
			else if (this.state.password.value.length < 8)
			{
				reject({ input: 'password', error: 'Password is too short.' });
			}
			else if(this.state.password.value !== this.state.confirmPassword.value)
			{
				reject({ input: 'password', error: 'Passwords do not match.' });
			}

			resolve();
		});
	}

	private submit(e:FormEvent) : void
	{
		e.preventDefault();
		
		this.verifyFormInputs()
		.then(() => {
			const accountDetails:ParentAccount = {
				firstName: this.state.firstName.value,
				lastName: this.state.lastName.value,
				email: this.state.email.value,
				password: this.state.password.value,
			};
			this.props.updateAccount(accountDetails);
		})
		.catch((rejection:FormRejection) => {
			const updatedState = { ...this.state };
			switch (rejection.input)
			{
				case 'email':
					updatedState.email.error = rejection.error;
					break;
				case 'password':
					updatedState.password.error = rejection.error;
					break;
				case 'firstName':
					updatedState.firstName.error = rejection.error;
					break;
				case 'lastName':
					updatedState.lastName.error = rejection.error;
					break;
			}
			this.setState(updatedState);
		});
	}

	private updateState(key:string, value:string) : void
	{
		let updatedState = { ...this.state };
		updatedState.firstName.error = null;
		updatedState.lastName.error = null;
		updatedState.email.error = null;
		updatedState.password.error = null;
		updatedState.confirmPassword.error = null;

		switch (key)
		{
			case 'firstName':
				updatedState.firstName.value = value;
				break;
			case 'lastName':
				updatedState.lastName.value = value;
				break;
			case 'email':
				updatedState.email.value = value;
				break;
			case 'password':
				updatedState.password.value = value;
				break;
			case 'confirmPassword':
				updatedState.confirmPassword.value = value;
				break;
		}

		this.setState(updatedState);
	}

	componentWillMount()
	{
		const accountDetails = sessionStorage.getItem('accountDetails');

		if (accountDetails)
		{
			const details:ParentAccount = JSON.parse(accountDetails);
			let updatedState = { ...this.state };
			updatedState.firstName.value = details.firstName;
			updatedState.lastName.value = details.lastName;
			updatedState.email.value = details.email;
			updatedState.password.value = details.password;
		}
	}

	render()
	{
		return (
			<form className="signup-form form-grid" noValidate onSubmit={ this.handleSubmitEvent }>
				<h1>Create Your Account</h1>
				<div className="-two-cols">
					<OutlineInput type="text" name="firstName" required={ true } label="First Name" form={ this.updateState.bind(this) } error={ this.state.firstName.error } value={ this.state.firstName.value } />
					<OutlineInput type="text" name="lastName" required={ true } label="Last Name" form={ this.updateState.bind(this) } error={ this.state.lastName.error } value={ this.state.lastName.value } />
				</div>
				<OutlineInput type="email" name="email" required={ true } label="Email Address" form={ this.updateState.bind(this) } error={ this.state.email.error } value={ this.state.email.value } />
				<div className="-two-cols">
					<OutlineInput type="password" name="password" required={ true } label="Password" form={ this.updateState.bind(this) } error={ this.state.password.error } value={ this.state.password.value } />
					<OutlineInput type="password" name="confirmPassword" required={ true } label="Confirm Password" form={ this.updateState.bind(this) } error={ this.state.confirmPassword.error } />
				</div>
				<p>Use 8 or more characters with a mix of letters and number</p>
				<div className="-space-between">
					<Link to="/">Sign in instead</Link>
					<Button type="submit" label="Next" classes="-solid -primary"></Button>
				</div>
			</form>
		);
	}
}

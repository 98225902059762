/** NPM Packages */
import React, { Component, FormEventHandler, FormEvent, createRef, RefObject, EventHandler } from 'react';

/** CSS */
import './policy-preview.scss';

interface PolicyPreviewProps
{
	acceptanceCallback: ()=>void,
}

interface PolicyPreviewState
{
	
}

export class PolicyPreview extends Component<PolicyPreviewProps, PolicyPreviewState>
{
	public window : RefObject<HTMLDivElement>;

	constructor(props:PolicyPreviewProps)
	{
		super(props);
		this.window = createRef<HTMLDivElement>();
	}

	private handleFormSubmit:FormEventHandler = this.submit.bind(this);
	private handleScrollEvent:EventHandler<any> = this.scroll.bind(this);

	private scroll(e:Event) : void
	{
		if (!this.window.current)
		{
			console.error('Missing policy window element');
			return;
		}

		const height = this.window.current.getBoundingClientRect().height;
		const totalHeight = this.window.current.scrollHeight;
		const scrolled = this.window.current.scrollTop;
		const newOffset = height;

		if (scrolled === totalHeight - newOffset)
		{
			this.props.acceptanceCallback();
		}
	}

	private submit(e:FormEvent) : void
	{
		e.preventDefault();
		if (!this.window.current)
		{
			console.error('Missing policy window element');
			return;
		}

		const height = this.window.current.getBoundingClientRect().height;
		const totalHeight = this.window.current.scrollHeight;
		const scrolled = this.window.current.scrollTop;
		const newOffset = height;

		if (scrolled !== totalHeight - newOffset)
		{
			this.window.current.scroll(0, (scrolled + newOffset));
		}
	}

	render()
	{
		return (
			<form noValidate onSubmit={ this.handleFormSubmit } className="policy-preview">
				<div className="-window" ref={ this.window } onScroll={ this.handleScrollEvent }>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue ut lectus arcu bibendum at varius vel. Eget velit aliquet sagittis id consectetur purus ut faucibus. Cras tincidunt lobortis feugiat vivamus at augue eget. Praesent elementum facilisis leo vel. Bibendum neque egestas congue quisque egestas diam in arcu cursus. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus. Turpis tincidunt id aliquet risus feugiat in ante. Arcu dui vivamus arcu felis bibendum ut tristique et egestas. Etiam erat velit scelerisque in dictum non consectetur. Natoque penatibus et magnis dis parturient. Dignissim sodales ut eu sem integer. Proin sed libero enim sed faucibus turpis in eu mi. Interdum posuere lorem ipsum dolor. Quis enim lobortis scelerisque fermentum. Dignissim sodales ut eu sem integer vitae justo. Viverra orci sagittis eu volutpat odio facilisis mauris sit amet.</p>
					<p>Proin sed libero enim sed faucibus turpis. Lorem ipsum dolor sit amet consectetur. Viverra adipiscing at in tellus integer. Est ante in nibh mauris cursus mattis molestie a iaculis. Habitasse platea dictumst quisque sagittis purus sit amet volutpat consequat. Eu consequat ac felis donec. Imperdiet massa tincidunt nunc pulvinar sapien et ligula. Lorem ipsum dolor sit amet consectetur adipiscing elit. Ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant. Id ornare arcu odio ut sem nulla pharetra diam. Consectetur libero id faucibus nisl tincidunt eget nullam non. Scelerisque varius morbi enim nunc faucibus. Bibendum enim facilisis gravida neque convallis a cras. Etiam erat velit scelerisque in dictum non consectetur a.</p>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue ut lectus arcu bibendum at varius vel. Eget velit aliquet sagittis id consectetur purus ut faucibus. Cras tincidunt lobortis feugiat vivamus at augue eget. Praesent elementum facilisis leo vel. Bibendum neque egestas congue quisque egestas diam in arcu cursus. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus. Turpis tincidunt id aliquet risus feugiat in ante. Arcu dui vivamus arcu felis bibendum ut tristique et egestas. Etiam erat velit scelerisque in dictum non consectetur. Natoque penatibus et magnis dis parturient. Dignissim sodales ut eu sem integer. Proin sed libero enim sed faucibus turpis in eu mi. Interdum posuere lorem ipsum dolor. Quis enim lobortis scelerisque fermentum. Dignissim sodales ut eu sem integer vitae justo. Viverra orci sagittis eu volutpat odio facilisis mauris sit amet.</p>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue ut lectus arcu bibendum at varius vel. Eget velit aliquet sagittis id consectetur purus ut faucibus. Cras tincidunt lobortis feugiat vivamus at augue eget. Praesent elementum facilisis leo vel. Bibendum neque egestas congue quisque egestas diam in arcu cursus. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus. Turpis tincidunt id aliquet risus feugiat in ante. Arcu dui vivamus arcu felis bibendum ut tristique et egestas. Etiam erat velit scelerisque in dictum non consectetur. Natoque penatibus et magnis dis parturient. Dignissim sodales ut eu sem integer. Proin sed libero enim sed faucibus turpis in eu mi. Interdum posuere lorem ipsum dolor. Quis enim lobortis scelerisque fermentum. Dignissim sodales ut eu sem integer vitae justo. Viverra orci sagittis eu volutpat odio facilisis mauris sit amet.</p>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Augue ut lectus arcu bibendum at varius vel. Eget velit aliquet sagittis id consectetur purus ut faucibus. Cras tincidunt lobortis feugiat vivamus at augue eget. Praesent elementum facilisis leo vel. Bibendum neque egestas congue quisque egestas diam in arcu cursus. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus. Turpis tincidunt id aliquet risus feugiat in ante. Arcu dui vivamus arcu felis bibendum ut tristique et egestas. Etiam erat velit scelerisque in dictum non consectetur. Natoque penatibus et magnis dis parturient. Dignissim sodales ut eu sem integer. Proin sed libero enim sed faucibus turpis in eu mi. Interdum posuere lorem ipsum dolor. Quis enim lobortis scelerisque fermentum. Dignissim sodales ut eu sem integer vitae justo. Viverra orci sagittis eu volutpat odio facilisis mauris sit amet.</p>
				</div>
				<button type="submit">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="13.105" viewBox="0 0 22 13.105">
  						<path id="angle-down-regular" d="M10.391,173.538.217,163.447a.826.826,0,0,1,0-1.169l1.361-1.361a.826.826,0,0,1,1.169,0l8.228,8.146,8.228-8.146a.826.826,0,0,1,1.169,0l1.361,1.361a.826.826,0,0,1,0,1.169L11.559,173.538A.826.826,0,0,1,10.391,173.538Z" transform="translate(0.025 -160.675)" fill="currentColor" />
					</svg>
				</button>
			</form>
		);
	}
}

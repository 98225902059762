/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './video-content.scss';

interface VideoContentProps
{
	contentId: string,
}

interface VideoContentState
{
	content: Video|null,
}

export class VideoContent extends Component<VideoContentProps, VideoContentState>
{
	constructor(props:VideoContentProps)
	{
		super(props);
		this.state = {
			content: null,
		}
	}

	componentWillMount()
	{
		console.warn('Fetching video content details is not implemented yet');

		setTimeout(() => {
			const updatedState = { ...this.state };
			updatedState.content = {
				title: 'Baking Cookied ',
				videoId: '3sL0omwElxw',
				videoDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
			};
			this.setState(updatedState);
		}, 3000);
	}

	render()
	{
		if (this.state.content)
		{
			return (
				<div className="video-content">
					<div className="-video-shim">
						<iframe title={ this.state.content.title } src={ `https://www.youtube-nocookie.com/embed/${ this.state.content.videoId }` } allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
					</div>
					<p>{ this.state.content.videoDescription }</p>
				</div>
			);
		}
		
		return (
			<div className="video-content">
				<div className="-circle-loader">
            		<div className="-circle"></div>
        		</div>
			</div>
		);
	}
}

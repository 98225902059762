/** NPM Packages */
import React from "react";

/** CSS */
import './not-found-view.scss';

export const NotFoundView = () => (
	<main data-view="not-found">
		<div>404 Page Not Found!</div>
	</main>
);

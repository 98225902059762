import React, { Component, EventHandler, SyntheticEvent, createRef, RefObject } from 'react';

/** CSS */
import './outline-input.scss';

type OutlineInputProps = {
	type: string,
	name: string,
	required : boolean,
	label : string,
	form : (key:string, value:string)=>void,
	error : string|null,
	value?: string
}

export class OutlineInput extends Component<OutlineInputProps>
{
	public input : RefObject<HTMLInputElement>;

	constructor(props:OutlineInputProps)
	{
		super(props);
		this.input = createRef<HTMLInputElement>();
	}

	private handleChangeEvent:EventHandler<SyntheticEvent> = this.checkInput.bind(this);

	private checkInput(e:SyntheticEvent) : void
	{
		if (!this.input.current)
		{
			return;
		}

		if (this.input.current.value)
		{
			this.input.current.classList.add('has-value');
		}
		else
		{
			this.input.current.classList.remove('has-value');
		}

		this.props.form(this.input.current.name, this.input.current.value);
	}

	componentDidUpdate()
	{
		if (this.props.error && this.input.current)
		{
			this.input.current.setCustomValidity(this.props.error);
			this.input.current.reportValidity();
		}
	}

	componentDidMount()
	{
		if (!this.input.current)
		{
			return;
		}

		if (this.input.current.value || this.input.current.type === 'date')
		{
			this.input.current.classList.add('has-value');
		}
		else
		{
			this.input.current.classList.remove('has-value');
		}
	}

	render()
	{
		return (
			<div className="outline-input">
				<input type={ this.props.type } required={ this.props.required } name={ this.props.name } onChange={ this.handleChangeEvent } ref={ this.input } value={ this.props.value } />
				<label>{ this.props.label }</label>
			</div>
		);
	}
}

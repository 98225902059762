/** NPM Packages */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './data-view.scss';

interface DataViewProps
{
	children: Array<Child>,
}

interface DataViewState
{
	
}

export class DataView extends Component<DataViewProps, DataViewState>
{

	private renderChild = (child:Child, i:number) => (
		<Link to={ `/dashboard/data/${ child.uid }` }>{ child.firstName }</Link>
	);

	render()
	{
		const children = this.props.children.map((child, i) => this.renderChild(child, i));
		return (
			<div className="data-view">
				<h1>Select an account to manage</h1>
				<nav>
					{ children }
				</nav>
			</div>
		);
	}
}

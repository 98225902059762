import React from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './footer.scss';

export const Footer = () => (
	<footer>
		<Link to="/">Help</Link>
		<Link to="/">Privacy Policy</Link>
		<Link to="/">Terms of Use</Link>
		<span>&copy; 2019 GEMS Girls' Club</span>
	</footer>
);

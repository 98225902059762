/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './home-view.scss';

/** Components */
import { ActivityOverview } from './activity-overview/activity-overview';

interface HomeViewProps
{
	user: User,
	children: Array<Child>,
}

interface HomeViewState
{
	
}

export class HomeView extends Component<HomeViewProps, HomeViewState>
{
	render()
	{
		return (
			<div className="home-view">
				<h1>Hello { this.props.user.firstName }.</h1>
				<p>How can we help you today?</p>
				<ActivityOverview children={ this.props.children } />
			</div>
		);
	}
}

/** NPM Packages */
import React, { Component, FormEventHandler, FormEvent } from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './add-child-account-form.scss';
import '../../form-grid.scss';

/** Components */
import { OutlineInput } from '../../outline-input/outline-input';
import { Button } from '../../button/button';

interface AddChildAccountFormProps
{
	addChildAccount: (child:Child)=>void,
}

interface AddChildAccountFormState
{
	child: {
		firstName: {
			value: string,
			error: string|null,
		},
		lastName: {
			value: string,
			error: string|null,
		},
		username: {
			value: string,
			error: string|null,
		},
		password: {
			value: string,
			error: string|null,
		},
		birthday: {
			value: string,
			error: string|null,
		},
	}
}

export class AddChildAccountForm extends Component<AddChildAccountFormProps, AddChildAccountFormState>
{
	constructor(props:AddChildAccountFormProps)
	{
		super(props);
		this.state = {
			child: {
				firstName: {
					value: '',
					error: null,
				},
				lastName: {
					value: '',
					error: null,
				},
				username: {
					value: '',
					error: null,
				},
				password: {
					value: '',
					error: null,
				},
				birthday: {
					value: '',
					error: null,
				},
			}
		};
	}

	private handleSubmitEvent:FormEventHandler = this.submit.bind(this);

	private validate() : Promise<FormRejection>
	{
		return new Promise((resolve, reject) => {
			if(this.state.child.firstName.value === '')
			{
				reject({ input: 'firstName', error: 'Please enter your first name.' });
			}
			else if(this.state.child.lastName.value === '')
			{
				reject({ input: 'lastName', error: 'Please enter your last name.' });
			}
			else if(this.state.child.username.value === '')
			{
				reject({ input: 'username', error: 'Please enter a username.' });
			}
			else if(this.state.child.password.value === '')
			{
				reject({ input: 'password', error: 'Please enter a password.' });
			}
			else if(this.state.child.password.value.length < 8)
			{
				reject({ input: 'password', error: 'Your password must be at least 8 characters.' });
			}
			else if(this.state.child.birthday.value === '')
			{
				reject({ input: 'birthday', error: 'Please enter your date of birth.' });
			}

			resolve();
		});
	}

	private submit(e:FormEvent) : void
	{
		e.preventDefault();
		this.validate()
		.then(() => {
			console.warn('Child account creation form is not implemented yet');
			const newChildAccount:Child = {
				firstName: this.state.child.firstName.value,
				lastName: this.state.child.lastName.value,
				username: this.state.child.username.value,
				password: this.state.child.password.value,
				birthday: this.state.child.birthday.value,
			};
			this.props.addChildAccount(newChildAccount);
		})
		.catch((rejection:FormRejection) => {
			let updatedState = { ...this.state };
			switch (rejection.input)
			{
				case 'firstName':
					updatedState.child.firstName.error = rejection.error;
					break;
				case 'lastName':
					updatedState.child.lastName.error = rejection.error;
					break;
				case 'username':
					updatedState.child.username.error = rejection.error;
					break;
				case 'password':
					updatedState.child.password.error = rejection.error;
					break;
				case 'birthday':
					updatedState.child.birthday.error = rejection.error;
					break;
			}
			this.setState(updatedState);
		});
	}

	public updateState(key:string, value:string) : void
	{
		let updatedState = { ...this.state };
		updatedState.child.firstName.error = null;
		updatedState.child.lastName.error = null;
		updatedState.child.username.error = null;
		updatedState.child.password.error = null;
		updatedState.child.birthday.error = null;

		switch (key)
		{
			case 'firstName':
				updatedState.child.firstName.value = value;
				break;
			case 'lastName':
				updatedState.child.lastName.value = value;
				break;
			case 'username':
				updatedState.child.username.value = value;
				break;
			case 'password':
				updatedState.child.password.value = value;
				break;
			case 'birthday':
				updatedState.child.birthday.value = value;
				break;
		}

		this.setState(updatedState);
	}

	render()
	{
		return (
			<form noValidate className="add-child-account-form form-grid" onSubmit={ this.handleSubmitEvent }>
				<h1>New Child Account</h1>
				<div className="-two-cols">
					<OutlineInput type="text" label="First Name" name="firstName" required={ true } form={ this.updateState.bind(this) } error={ this.state.child.firstName.error } />
					<OutlineInput type="text" label="Last Name" name="lastName" required={ true } form={ this.updateState.bind(this) } error={ this.state.child.lastName.error } />
				</div>
				<OutlineInput type="text" label="Username" name="username" required={ true } form={ this.updateState.bind(this) } error={ this.state.child.username.error } />
				<div className="-two-cols">
					<OutlineInput type="password" label="Password" name="password" required={ true } form={ this.updateState.bind(this) } error={ this.state.child.password.error } />
					<OutlineInput type="date" label="Birthday" name="birthday" required={ true } form={ this.updateState.bind(this) } error={ this.state.child.birthday.error } />
				</div>
				<p>Use 8 or more characters with a mix of letters and number</p>
				<div className="-space-between">
					<Link to="/register/link-child">Sign in instead</Link>
					<div>
						<Link className="g-button -outline -grey" to="/register/children">Back</Link>
						<Button type="submit" label="Next" classes="-solid -primary" />
					</div>
				</div>
			</form>
		);
	}
}

/** NPM Packages */
import React, { Component, MouseEventHandler, Fragment } from 'react';

/** CSS */
import './user-data-manager.scss';

/** Components */
import { ConfirmationModal } from '../../../confirmation-modal/confirmation-modal';
import { UserDataTable } from './user-data-table/user-data-table';

interface UserDataManagerProps
{
	userId: string,
}

interface UserDataManagerState
{
	openModalType: string|null,
	page: number,
	listItems: Array<SelectedListItem>,
	total: number,
	userActivity: Array<Activity>|null,
	userCanDeleteSelected: boolean,
	userSelectedAll: boolean,
}

export class UserDataManager extends Component<UserDataManagerProps, UserDataManagerState>
{
	constructor(props:UserDataManagerProps)
	{
		super(props);
		this.state = {
			openModalType: null,
			page: 0,
			listItems: [],
			total: 0,
			userActivity: null,
			userCanDeleteSelected: false,
			userSelectedAll: false,
		}
	}

	private handleDeleteAllButtonClickEvent:MouseEventHandler<HTMLButtonElement> = this.promptAllDelete.bind(this);
	private handleDeleteSelectedButtonClickEvent:MouseEventHandler<HTMLButtonElement> = this.promptSelectedDelete.bind(this);

	private promptSelectedDelete() : void
	{
		const updatedState = { ...this.state };
		updatedState.openModalType = 'selected';
		this.setState(updatedState);
	}

	private promptAllDelete() : void
	{
		const updatedState = { ...this.state };
		updatedState.openModalType = 'all';
		this.setState(updatedState);
	}

	private closeModal() : void
	{
		const updatedState = { ...this.state };
		updatedState.openModalType = null;
		this.setState(updatedState);
	}

	private purgeAllData() : void
	{
		console.warn('All data deletion has not been implemented yet');
		this.closeModal();
	}

	private purgeSelectedData() : void
	{
		console.warn('Selected data deletion has not been implemented yet');
		this.closeModal();
	}

	private renderDeleteAllModal = () => (
		<ConfirmationModal title="Delete All Recorded Activity Data?" body="This action cannot be undone. The data will be permanently deleted from the account." confirmCallback={ this.purgeAllData.bind(this) } closeModal={ this.closeModal.bind(this) } />
	);

	private renderDeleteSelectedModal = () => (
		<ConfirmationModal title="Delete Selected Activity Data?" body="This action cannot be undone. The data will be permanently deleted from the account." confirmCallback={ this.purgeSelectedData.bind(this) } closeModal={ this.closeModal.bind(this) } />
	);

	public loadNextPage() : void
	{
		const updatedState = { ...this.state };
		const maxPage = Math.ceil(this.state.total / 10) - 1;
		updatedState.page++;

		if (updatedState.page > maxPage)
		{
			updatedState.page = maxPage;
		}

		this.fetchNewPage(updatedState);
	}

	public loadPrevPage() : void
	{
		const updatedState = { ...this.state };
		updatedState.page--;

		if (updatedState.page < 0)
		{
			updatedState.page = 0;
		}

		this.fetchNewPage(updatedState);
	}

	componentWillMount()
	{
		console.warn('Fetching user activity data has not been implemented yet');
		const updatedState = { ...this.state };
		updatedState.page = 0;
		this.fetchNewPage(updatedState);
	}

	private fetchNewPage(updatedState:UserDataManagerState) : void
	{
		console.warn('Fetching user activity pages has not been implemented yet');
		
		fetch(`${ window.location.origin }/api/p${ updatedState.page }.json`)
		.then(request => request.json())
		.then(response => {
			if (response.success)
			{
				updatedState.userActivity = response.activity;
				updatedState.total = response.total;
				updatedState.listItems = [];
				for (let i = 0; i < response.activity.length; i++)
				{
					const newActiveActivityListItem:SelectedListItem = {
						...response.activity[i],
						isSelected: false,
					}
					updatedState.listItems.push(newActiveActivityListItem);
				}
				this.setState(updatedState);
			}
		})
		.catch(error => {
			console.error('Failed to fetch user activity data', error);
		});
	}

	public updateSelectedListItems(activity:Activity, status:boolean) : void
	{
		const updatedState = { ...this.state };
		let userCanDeleteSelected = false;
		for (let i = 0; i < updatedState.listItems.length; i++)
		{
			if (updatedState.listItems[i].uid === activity.uid)
			{
				updatedState.listItems[i].isSelected = status;
			}

			if (updatedState.listItems[i].isSelected)
			{
				userCanDeleteSelected = true;
			}
		}

		if (userCanDeleteSelected)
		{
			updatedState.userCanDeleteSelected = true;
		}
		else
		{
			updatedState.userCanDeleteSelected = false;
		}

		this.setState(updatedState);
	}

	public selectAllListItems() : void
	{
		const updatedState = { ...this.state };

		if (!updatedState.userSelectedAll)
		{
			updatedState.userCanDeleteSelected = true;
			updatedState.userSelectedAll = true;
			for (let i = 0; i < updatedState.listItems.length; i++)
			{
				updatedState.listItems[i].isSelected = true;
			}
			this.setState(updatedState);
			}
		else
		{
			updatedState.userCanDeleteSelected = false;
			updatedState.userSelectedAll = false;
			for (let i = 0; i < updatedState.listItems.length; i++)
			{
				updatedState.listItems[i].isSelected = false;
			}
			this.setState(updatedState);
		}
	}

	render()
	{
		let confirmModal = null;

		switch(this.state.openModalType)
		{
			case 'all':
				confirmModal = this.renderDeleteAllModal();
				break;
			case 'selected':
				confirmModal = this.renderDeleteSelectedModal();
				break;
		}

		return (
			<Fragment>
				<div className="user-data-manager">
					<header>
						<span>Activity Overview</span>
						<div>
							<button onClick={ this.handleDeleteAllButtonClickEvent } className="g-button -danger -outline">Delete All</button>
							<button onClick={ this.handleDeleteSelectedButtonClickEvent } className={`g-button -danger -solid ${ (this.state.userCanDeleteSelected) ? '' : 'is-disabled' }`}>Delete Selected</button>
						</div>
					</header>
					<UserDataTable updateSelectedActivityList={ this.updateSelectedListItems.bind(this) } activity={ this.state.listItems } page={ this.state.page } total={ this.state.total } loadNextPage={ this.loadNextPage.bind(this) } loadPrevPage={ this.loadPrevPage.bind(this) } changeSelectAllState={ this.selectAllListItems.bind(this) } selectAllStatus={ this.state.userSelectedAll } />
				</div>
				{ confirmModal }
			</Fragment>
		);
	}
}

/** NPM Packages */
import React, { Component, MouseEventHandler, Fragment } from 'react';

/** CSS */
import './child-account-manager.scss';

/** Components */
import { ConfirmationModal } from '../../../confirmation-modal/confirmation-modal';

interface ChildAccountManagerProps
{
	child: Child,
	unlinkChildAccount: (childId:string)=>void,
	deleteChildAccount: (childId:string)=>void,
}

interface ChildAccountManagerState
{
	modal: string|null,
}

export class ChildAccountManager extends Component<ChildAccountManagerProps, ChildAccountManagerState>
{
	constructor(props:ChildAccountManagerProps)
	{
		super(props);
		this.state = {
			modal: null,
		}
	}

	private handleUnlinkButtonClickEvent:MouseEventHandler<HTMLButtonElement> = this.promptUnlinkMessage.bind(this);
	private handleDeleteButtonClickEvent:MouseEventHandler<HTMLButtonElement> = this.promptDeleteMessage.bind(this);

	private promptUnlinkMessage() : void
	{
		const updatedState = { ...this.state };
		updatedState.modal = 'unlink';
		this.setState(updatedState);
	}

	private promptDeleteMessage() : void
	{
		const updatedState = { ...this.state };
		updatedState.modal = 'delete';
		this.setState(updatedState);
	}

	public confirmUnlink() : void
	{
		if (!this.props.child.uid)
		{
			return;
		}
		this.closeModal();
		this.props.unlinkChildAccount(this.props.child.uid);
	}

	public confirmDelete() : void
	{
		if (!this.props.child.uid)
		{
			return;
		}
		this.closeModal();
		this.props.deleteChildAccount(this.props.child.uid);
	}

	public closeModal() : void
	{
		const updatedState = { ...this.state };
		updatedState.modal = null;
		this.setState(updatedState);
	}

	private renderUnlinkPrompt = () => (
		<ConfirmationModal closeModal={ this.closeModal.bind(this) } confirmCallback={ this.confirmUnlink.bind(this) } title="Unlink Account?" body="When unlinked the account will no longer appear as a part of your family and you will lose the ability to manage the accounts collected data." />
	);

	private renderDeletePrompt = () => (
		<ConfirmationModal closeModal={ this.closeModal.bind(this) } confirmCallback={ this.confirmDelete.bind(this) } title="Delete Account?" body="This action cannot be undone. The account will be permanently deleted." />
	);

	render()
	{
		let modal = null;
		
		switch (this.state.modal)
		{
			case 'unlink':
				modal = this.renderUnlinkPrompt();
				break;
			case 'delete':
					modal = this.renderDeletePrompt();
				break;
		}

		return (
			<Fragment>
				<div className="child-account-manager">
					<h2>{ this.props.child.firstName }</h2>
					<span>{ this.props.child.username }</span>
					<div className="-flex-end">
						<button onClick={ this.handleUnlinkButtonClickEvent } className="g-button -text -grey">Unlink</button>
						<button onClick={ this.handleDeleteButtonClickEvent } className="g-button -text -danger">Delete</button>
					</div>
				</div>
				{ modal }
			</Fragment>
		);
	}
}

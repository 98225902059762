/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './checkbox-input.scss';

interface CheckboxInputProps
{
	changeState: ()=>void,
	name: string,
	label: string|null,
	isChecked: boolean,
}

interface CheckboxInputState
{
	
}

export class CheckboxInput extends Component<CheckboxInputProps, CheckboxInputState>
{
	render()
	{
		return (
			<div className="checkbox-input">
				<input type="checkbox" name={ this.props.name } id={ this.props.name } onChange={ this.props.changeState } checked={ this.props.isChecked } />
				<label htmlFor={ this.props.name }>
					<div className="-checkbox">
						<svg xmlns="http://www.w3.org/2000/svg" width="12.695" height="9.457" viewBox="0 0 12.695 9.457">
  							<path d="M571.344,492.322l3.945,3.945,7.336-7.336" transform="translate(-570.637 -488.224)" fill="none" stroke="currentColor" strokeWidth="2"/>
						</svg>
					</div>
					{ this.props.label }
				</label>
			</div>
		);
	}
}

/** NPM Packages */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './dashboard-navigation.scss';

interface DashboardNavigationProps
{
	active: string
}

interface DashboardNavigationState
{
	
}

export class DashboardNavigation extends Component<DashboardNavigationProps, DashboardNavigationState>
{
	render()
	{
		return (
			<nav className="dashboard-navigation">
				<Link className={ (this.props.active === 'home' ? 'is-active' : '') } to="/dashboard/home">Home</Link>
				<Link className={ (this.props.active === 'data' ? 'is-active' : '') } to="/dashboard/data">Data Management</Link>
				<Link className={ (this.props.active === 'family' ? 'is-active' : '') } to="/dashboard/family">Family Management</Link>
				<Link className={ (this.props.active === 'help' ? 'is-active' : '') } to="/dashboard/help">Help</Link>
				<Link className={ (this.props.active === 'support' ? 'is-active' : '') } to="/dashboard/support">Contact Support</Link>
			</nav>
		);
	}
}

/** NPM Packages */
import React, { Component, Fragment } from "react";

/** CSS */
import './dashboard-view.scss';

/** Components */
import { Footer } from '../footer/footer';
import { HomeView } from './home-view/home-view';
import { DashboardHeader } from './dashboard-header/dashboard-header';
import { DashboardNavigation } from './dashboard-navigation/dashboard-navigation';
import { DataView } from './data-view/data-view';
import { UserDataView } from './user-data-view/user-data-view';
import { FamilyView } from './family-view/family-view';

interface DashboardViewProps
{
	view: string,
	match: { params: { slug: string, uid?: string } },
	history: any,
}

interface DashboardViewState
{
	user: User,
	children: Array<Child>,
}

export class DashboardView extends Component<DashboardViewProps, DashboardViewState>
{
	constructor(props:DashboardViewProps)
	{
		super(props);
		this.state = {
			user: {
				firstName: 'Carol',
				lastName: 'Rawlings',
				email: 'carolmrawlings@gmail.com',
			},
			children: [
				{
					firstName: 'Alice',
					lastName: 'Rawlings',
					username: 'sodapopfan18',
					uid: 'f8691242-396b-4b3d-b925-dd7cc9abc936',
				},
				{
					firstName: 'Brooke',
					lastName: 'Rawlings',
					username: 'wildcatkid2000',
					uid: 'f6c66a1e-d173-41ca-bdf4-a26febbeb79b',
				}
			]
		};
	}

	public unlinkChildAccount(childId:string) : void
	{
		console.warn('Unlinking child account feature has not been implemented yet');
		const updatedState = { ...this.state };
		const newChildren = [];

		for (let i = 0; i < updatedState.children.length; i++)
		{
			if (updatedState.children[i].uid !== childId)
			{
				newChildren.push(updatedState.children[i]);
			}
		}

		updatedState.children = newChildren;
		this.setState(updatedState);
	}

	public deleteChildAccount(childId:string) : void
	{
		console.warn('Deleting child account feature has not been implemented yet');
		const updatedState = { ...this.state };
		const newChildren = [];

		for (let i = 0; i < updatedState.children.length; i++)
		{
			if (updatedState.children[i].uid !== childId)
			{
				newChildren.push(updatedState.children[i]);
			}
		}

		updatedState.children = newChildren;
		this.setState(updatedState);
	}

	private home = () => (
		<HomeView user={ this.state.user } children={ this.state.children } />
	);

	private data = () => (
		<DataView children={ this.state.children } />
	);

	private userData = (uid:string) => (
		<UserDataView userId={ uid } children={ this.state.children } history={ this.props.history } />
	);

	private family = () => (
		<FamilyView children={ this.state.children } unlinkChildAccount={ this.unlinkChildAccount.bind(this) } deleteChildAccount={ this.deleteChildAccount.bind(this) } />
	);

	private help = () => (
		<div>Help</div>
	);

	private support = () => (
		<div>Contact Support</div>
	);
	
	render()
	{
		let view = null;
		switch (this.props.match.params.slug.toLowerCase())
		{
			case 'data':
				view = (this.props.match.params.uid) ? this.userData(this.props.match.params.uid) : this.data();
				break;
			case 'family':
				view = this.family();
				break;
			case 'help':
				view = this.help();
				break;
			case 'support':
				view = this.support();
				break;
			default:
				view = this.home();
				break;
		}
		return (
			<Fragment>
				<DashboardHeader user={ this.state.user } />
				<main data-view="dashboard">
					{ view }
					<Footer />
				</main>
				<DashboardNavigation active={ this.props.match.params.slug } />
			</Fragment>
		);
	}
}

/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './poll-content.scss';

interface PollContentProps
{
	contentId: string,
}

interface PollContentState
{
	content: Poll|null,
}

export class PollContent extends Component<PollContentProps, PollContentState>
{
	constructor(props:PollContentProps)
	{
		super(props);
		this.state = {
			content: null,
		}
	}

	componentWillMount()
	{
		console.warn('Fetching poll content details is not implemented yet');

		setTimeout(() => {
			const updatedState = { ...this.state };
			updatedState.content = {
				title: 'Sample poll title',
				collectedInformation: [
					{
						question: 'Favorite color?',
						answer: 'Purple',
					},
					{
						question: 'Favorite animal?',
						answer: 'Panda',
					},
					{
						question: 'What is your favorite kind of vacation?',
						answer: 'A stay-cation at home with my family',
					},
					{
						question: 'What do you wear in the summer?',
						answer: 'A pretty summer dress',
					},
					{
						question: 'What is your favorite thing to do on a hot summer day?',
						answer: 'Have a beach day',
					},
				]
			};
			this.setState(updatedState);
		}, 3000);
	}

	private renderOption = (data:QuizData, i:number) => (
		<div key={ i }>
			<dt>{ data.question }</dt>
			<dd>{ data.answer }</dd>
		</div>
	);

	render()
	{
		if (this.state.content)
		{
			const pollOptions = this.state.content.collectedInformation.map((option, i) => this.renderOption(option, i));
			return (
				<div className="poll-content">
					<dl>
						{ pollOptions }
					</dl>
				</div>
			);
		}
		
		return (
			<div className="poll-content">
				<div className="-circle-loader">
            		<div className="-circle"></div>
        		</div>
			</div>
		);
	}
}

/** NPM Packages */
import React, { Component, Fragment, MouseEventHandler } from 'react';

/** CSS */
import './activity-list-item.scss';

/** Components */
import { ContentModal } from '../../../../../content-modal/content-modal';
import { CheckboxInput } from '../../../../../checkbox-input/checkbox-input';

interface ActivityListItemProps
{
	activity: SelectedListItem,
	id: string,
	updateSelectedItemsList: (activity:Activity, status:boolean)=>void
}

interface ActivityListItemState
{
	modalIsOpen: boolean,
	isChecked: boolean,
}

export class ActivityListItem extends Component<ActivityListItemProps, ActivityListItemState>
{
	constructor(props:ActivityListItemProps)
	{
		super(props);
		this.state = {
			modalIsOpen: false,
			isChecked: false,
		}
	}

	private handleViewButtonClick:MouseEventHandler<HTMLButtonElement> = this.openModal.bind(this);

	private openModal() : void
	{
		const updatedState = { ...this.state };
		updatedState.modalIsOpen = true;
		this.setState(updatedState);
	}

	private closeModal() : void
	{
		const updatedState = { ...this.state };
		updatedState.modalIsOpen = false;
		this.setState(updatedState);
	}

	private renderContentModal = (contentId:string, contentType:string, contentTitle:string) => (
		<ContentModal closeModal={ this.closeModal.bind(this) } modalIsOpen={ this.state.modalIsOpen } contentId={ contentId } contentType={ contentType } contentTitle={ contentTitle } />
	);

	public changeCheckboxState() : void
	{
		const updatedState = { ...this.state };
		
		if (updatedState.isChecked)
		{
			updatedState.isChecked = false;
		}
		else
		{
			updatedState.isChecked = true;
		}

		this.setState(updatedState);
		this.props.updateSelectedItemsList(this.props.activity, updatedState.isChecked);
	}

	render()
	{
		let contentModal = null;

		if (this.state.modalIsOpen)
		{
			contentModal = this.renderContentModal(this.props.activity.contentId, this.props.activity.contentType, this.props.activity.contentTitle);
		}

		return (
			<Fragment>
				<li className="activity-list-item">
					<span>
						<CheckboxInput changeState={ this.changeCheckboxState.bind(this) } name={ `activity-${ this.props.id }` } label={ null } isChecked={ this.props.activity.isSelected } />
					</span>
					<span>{ this.props.activity.type }</span>
					<span>{ this.props.activity.contentTitle }</span>
					<span>{ this.props.activity.dateRecorded }</span>
					<span>
						<button onClick={ this.handleViewButtonClick } className="g-button -solid -primary">View</button>
					</span>
				</li>
				{ contentModal }
			</Fragment>
		);
	}
}

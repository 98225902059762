/** NPM Packages */
import React, { Component, MouseEventHandler } from 'react';
import Modal from 'react-modal';

/** CSS */
import './content-modal.scss';

/** Components */
import { VideoContent } from './video-content/video-content';
import { ArticleContent } from './article-content/article-content';
import { PollContent } from './poll-content/poll-content';

interface ContentModalProps
{
	modalIsOpen: boolean,
	closeModal: MouseEventHandler<any>,
	contentId: string,
	contentType: string,
	contentTitle: string,
}

interface ContentModalState
{
	
}

export class ContentModal extends Component<ContentModalProps, ContentModalState>
{
	private handleModalCloseClickEvent:MouseEventHandler<HTMLButtonElement> = this.props.closeModal;

	componentDidMount()
	{
		const appShell:HTMLElement|null = document.body.querySelector('app-shell');

		if (appShell)
		{
			Modal.setAppElement(appShell);
		}
	}

	private renderVideoContent = () => (
		<VideoContent contentId={ this.props.contentId } />
	);

	private renderArticleContent = () => (
		<ArticleContent contentId={ this.props.contentId } />
	);

	private renderPollContent = () => (
		<PollContent contentId={ this.props.contentId } />
	);

	render()
	{
		let content = null;
		switch (this.props.contentType.toLowerCase())
		{
			case 'video':
				content = this.renderVideoContent();
				break;
			case 'article':
				content = this.renderArticleContent();	
				break;
			case 'poll':
				content = this.renderPollContent();
				break;
		}
		return (
			<Modal isOpen={ this.props.modalIsOpen } onRequestClose={ this.props.closeModal } className="content-modal" overlayClassName="content-modal-backdrop" >
				<header>{ this.props.contentTitle }</header>
				{ content }
				<div className="-flex-end">
					<button onClick={ this.handleModalCloseClickEvent } className="g-button -solid -primary">Close</button>
				</div>
			</Modal>
		);
	}
}

/** NPM Packages */
import React, { Component } from 'react';

/** CSS */
import './dashboard-header.scss';

/** Components */
import { HeaderUserController } from './header-user-controller/header-user-controller';

interface DashboardHeaderProps
{
	user: User
}

interface DashboardHeaderState
{
	
}

export class DashboardHeader extends Component<DashboardHeaderProps, DashboardHeaderState>
{
	render()
	{
		return (
			<div className="dashboard-header">
				<svg xmlns="http://www.w3.org/2000/svg" width="134.833" height="41.993" viewBox="0 0 134.833 41.993">
					<g id="GEMS_Logo" data-name="GEMS Logo" transform="translate(0 0.009)">
						<g id="Layer_1" data-name="Layer 1" transform="translate(0 -0.009)">
						<path id="Path_1" data-name="Path 1" d="M0,41.57C0,33.3,6.977,26.579,15.565,26.53c6.663,0,10.41,4.164,10.41,8.225,0,2.243-1.2,4.684-3.59,4.684A3.51,3.51,0,0,1,19.159,36a4.876,4.876,0,0,0,1.979-2.288c.624-1.252,1.4-3.59-.624-5.259a6.73,6.73,0,0,0-4.424-1.2c-5.833.417-7.655,5.833-7.655,14.264,0,8.077,1.822,13.91,7.134,14.358a8.3,8.3,0,0,0,4.173-.722V43.966h-3.45v-.574h9.472V55.457H21.5a17.468,17.468,0,0,1-5.932,1.09C6.977,56.561,0,49.849,0,41.57Z" transform="translate(0 -14.622)" fill="#f05786"/>
						<path id="Path_2" data-name="Path 2" d="M69.49,27.34H91.772v9.683h-.516c0-5.416-4.213-9.162-10.253-9.162H77.347V41.393h.628c4.424-.054,7.237-2.342,7.237-6.349h.624V48.276h-.624c0-3.953-2.813-6.246-7.134-6.349h-.731v14.04H80.99c6.039,0,11.765-4.007,12.115-9.423h.52l-.9,9.939H69.49Z" transform="translate(-38.31 -15.069)" fill="#f49654"/>
						<path id="Path_3" data-name="Path 3" d="M135.45,28.511V56.465h-.52V27.3h8.588l8.431,18.4,7.691-18.379H167.5V56.483H159.64V28.619L147.525,57.04Z" transform="translate(-74.387 -15.046)" fill="#54c3bb"/>
						<path id="Path_4" data-name="Path 4" d="M216.84,48.324a3.378,3.378,0,0,1,3.59-3.437,4.032,4.032,0,0,1,4.218,4.011,2.2,2.2,0,0,0-1.822,1.822,4.564,4.564,0,0,0-.054.83c0,3.02,3.383,4.272,6.246,4.272,2.19,0,6.093-.731,6.093-3.9,0-6.56-16.6-7.6-17.544-16.086-.727-6.506,4.738-9.423,11.19-9.423,5.259,0,10.257,2.759,10.257,7.444a2.912,2.912,0,0,1-3.141,2.966,3.54,3.54,0,0,1-3.7-3.486s1.768-.206,1.768-2.813c0-2.077-2.028-3.379-5.1-3.379-2.755,0-5.151,1.09-5.151,3.9,0,7.076,16.813,7.628,17.54,15.354.835,6.506-5.1,10.1-11.917,10.1C222.929,56.5,216.84,53.426,216.84,48.324Z" transform="translate(-119.545 -14.556)" fill="#ed5654"/>
						<path id="Path_5" data-name="Path 5" d="M213.055,7.579a1.624,1.624,0,0,0-2.472,1.871c.588,1.449,3.944,1.795,3.944,1.795s2.2-2.567,1.606-4.038a1.629,1.629,0,0,0-3.078.372Z" transform="translate(-116.042 -3.447)" fill="#f05786"/>
						<path id="Path_6" data-name="Path 6" d="M237.652.807l-.61.655a1.889,1.889,0,0,0-1.436-.57,1.6,1.6,0,0,0-1.153.512A1.517,1.517,0,0,0,234,2.553a1.557,1.557,0,0,0,.538,1.144,1.656,1.656,0,0,0,1.229.426,1.517,1.517,0,0,0,.785-.229,1.346,1.346,0,0,0,.489-.637l-1.373.058-.036-.848,2.387-.1v.2a2.338,2.338,0,0,1-.269,1.194,2.27,2.27,0,0,1-.8.9A2.355,2.355,0,0,1,235.763,5a2.661,2.661,0,0,1-1.346-.265,2.356,2.356,0,0,1-.9-.893,2.54,2.54,0,0,1-.39-1.27,2.4,2.4,0,0,1,.552-1.678,2.441,2.441,0,0,1,1.889-.9,2.885,2.885,0,0,1,1.167.179,2.634,2.634,0,0,1,.92.633Z" transform="translate(-128.52 0.009)" fill="#f49654"/>
						<path id="Path_7" data-name="Path 7" d="M248.776,1.994l.866.153-.6,3.459-.87-.153Zm.682-1.346a.538.538,0,0,1,.363.233.565.565,0,0,1,.094.449.547.547,0,0,1-.233.368.525.525,0,0,1-.413.094.538.538,0,0,1-.368-.233.574.574,0,0,1-.094-.449.538.538,0,0,1,.233-.363.534.534,0,0,1,.4-.1Z" transform="translate(-136.817 -0.347)" fill="#f49654"/>
						<path id="Path_8" data-name="Path 8" d="M254.964,5.13l.718.229-.135.422A.965.965,0,0,1,256,5.5a.772.772,0,0,1,.449,0,.758.758,0,0,1,.314.193l-.494.642a.565.565,0,0,0-.215-.153.417.417,0,0,0-.408.135,2.355,2.355,0,0,0-.449.9l-.049.166-.449,1.346-.835-.269Z" transform="translate(-139.954 -2.824)" fill="#f49654"/>
						<path id="Path_9" data-name="Path 9" d="M263.169,5.44l.794.381-2.109,4.388-.794-.381Z" transform="translate(-143.923 -2.995)" fill="#f49654"/>
						<path id="Path_10" data-name="Path 10" d="M270.2,13.634l-.758.144a.924.924,0,0,0-.31-.615.417.417,0,0,0-.229-.081.2.2,0,0,0-.157.081.206.206,0,0,0-.031.139,1.05,1.05,0,0,0,.126.283l.175.319a1.876,1.876,0,0,1,.287.821,1.032,1.032,0,0,1-.17.61,1.01,1.01,0,0,1-.727.449,1.315,1.315,0,0,1-.938-.26,1.476,1.476,0,0,1-.673-1.189l.785-.184a1.2,1.2,0,0,0,.13.449.852.852,0,0,0,.242.3.449.449,0,0,0,.278.09.251.251,0,0,0,.2-.1c.076-.112.045-.292-.094-.547l-.162-.287a1.261,1.261,0,0,1-.094-1.369.924.924,0,0,1,.669-.408,1.171,1.171,0,0,1,.844.224,1.378,1.378,0,0,1,.449.489A1.414,1.414,0,0,1,270.2,13.634Z" transform="translate(-147.082 -6.761)" fill="#f49654"/>
						<path id="Path_11" data-name="Path 11" d="M278.869,14.74l.606.565-.3.328a2.306,2.306,0,0,1-.565.476,2.373,2.373,0,0,1-.687.229l-.072-.395a1.843,1.843,0,0,0,.332-.1,3.072,3.072,0,0,0,.31-.175l-.242-.22Z" transform="translate(-153.18 -8.122)" fill="#f49654"/>
						<path id="Path_12" data-name="Path 12" d="M285.1,27.182,284.243,27a1.965,1.965,0,0,0-.26-1.584A1.593,1.593,0,0,0,281.739,25a1.7,1.7,0,0,0-.579.651,1.467,1.467,0,0,0-.148.808,1.709,1.709,0,0,0,.3.808,1.687,1.687,0,0,0,.529.512,2.486,2.486,0,0,0,.848.287l-.184.87a3.509,3.509,0,0,1-1.149-.417,2.692,2.692,0,0,1-.767-.772,2.526,2.526,0,0,1,1.862-3.94,2.4,2.4,0,0,1,1.252.256,2.589,2.589,0,0,1,1.005.875,2.692,2.692,0,0,1,.386,2.243Z" transform="translate(-154.448 -13.116)" fill="#f49654"/>
						<path id="Path_13" data-name="Path 13" d="M289.907,35.61l.341.812-4.487,1.885-.341-.817Z" transform="translate(-157.353 -19.628)" fill="#f49654"/>
						<path id="Path_14" data-name="Path 14" d="M291.353,43.93l.193.87-1.651.372a2.36,2.36,0,0,0-.655.215.579.579,0,0,0-.242.278.709.709,0,0,0,.161.727.6.6,0,0,0,.35.153,2.185,2.185,0,0,0,.646-.085l1.678-.377.193.861-1.454.323a3.652,3.652,0,0,1-1.261.135,1.346,1.346,0,0,1-.731-.372,1.6,1.6,0,0,1-.408-.808,1.579,1.579,0,0,1,.045-.969,1.346,1.346,0,0,1,.6-.646,4.165,4.165,0,0,1,1.108-.354Z" transform="translate(-158.84 -24.215)" fill="#f49654"/>
						<path id="Path_15" data-name="Path 15" d="M294.358,57.667l-1.745-.031a1.557,1.557,0,0,1-.1,2.279,1.884,1.884,0,0,1-1.346.449,1.826,1.826,0,0,1-1.3-.525,1.566,1.566,0,0,1-.507-1.189,1.445,1.445,0,0,1,.121-.561,1.687,1.687,0,0,1,.363-.512h-.372v-.9l4.868.094Zm-2.1.9a.924.924,0,0,0-.269-.7,1.014,1.014,0,0,0-.736-.292,1.072,1.072,0,0,0-.767.26.956.956,0,0,0-.022,1.346,1.05,1.05,0,0,0,.745.292,1.028,1.028,0,0,0,.749-.256.9.9,0,0,0,.3-.664Z" transform="translate(-159.525 -31.244)" fill="#f49654"/>
						<path id="Path_16" data-name="Path 16" d="M290.783,71.389l-.4-.655a.929.929,0,0,0,.449-.512.4.4,0,0,0,0-.242.2.2,0,0,0-.135-.117.175.175,0,0,0-.139,0,.83.83,0,0,0-.22.215l-.233.278a1.853,1.853,0,0,1-.655.583,1.036,1.036,0,0,1-.642.04,1,1,0,0,1-.678-.489,1.346,1.346,0,0,1-.09-.969,1.463,1.463,0,0,1,.853-1.072l.449.669a1.2,1.2,0,0,0-.368.283.785.785,0,0,0-.2.337.449.449,0,0,0,0,.292.238.238,0,0,0,.162.148c.135.031.292-.063.476-.283l.215-.256a1.258,1.258,0,0,1,1.243-.574.9.9,0,0,1,.619.48,1.14,1.14,0,0,1,.094.866,1.476,1.476,0,0,1-.3.592,1.4,1.4,0,0,1-.5.386Z" transform="translate(-158.767 -37.733)" fill="#f49654"/>
						</g>
					</g>
				</svg>
				<HeaderUserController user={ this.props.user } />
			</div>
		);
	}
}

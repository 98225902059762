/** NPM Packages */
import React, { Component, FormEventHandler, FormEvent } from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './child-login-form.scss';
import '../../form-grid.scss';

/** Components */
import { OutlineInput } from '../../outline-input/outline-input';
import { Button } from '../../button/button';

interface ChildLoginFormProps
{
	linkChildAccount: (childAccount:Child)=>void,
}

interface ChildLoginFormState
{
	username: { value: string, error: null|string },
	password: { value: string, error: null|string },
}

export class ChildLoginForm extends Component<ChildLoginFormProps, ChildLoginFormState>
{
	constructor(props:ChildLoginFormProps)
	{
		super(props);
		this.state = {
			username: { value: '', error: null },
			password: { value: '', error: null }
		}
	}

	private handleFormSubmit:FormEventHandler = this.submit.bind(this);
	
	private validate() : Promise<FormRejection>
	{
		return new Promise((resolve, reject) => {
			if(this.state.username.value === '')
			{
				reject({ input: 'username', error: 'Please enter your username.' });
			}
			else if(this.state.password.value === '')
			{
				reject({ input: 'password', error: 'Please enter your password.' });
			}

			resolve();
		});
	}

	private submit(e:FormEvent) : void
	{
		e.preventDefault();
		this.validate()
		.then(() => {
			console.warn('Child account login form is not implemented yet');

			const tempChild:Child = {
				firstName: 'Alice',
				lastName: 'Boot',
				username: 'sodapopfan18',
			};

			this.props.linkChildAccount(tempChild);
		})
		.catch((rejection:FormRejection) => {
			const updatedState = { ...this.state };
			switch (rejection.input)
			{
				case 'username':
					updatedState.username.error = rejection.error;
					break;
				case 'password':
					updatedState.password.error = rejection.error;
					break;
			}
			this.setState(updatedState);
		});
	}

	private updateState(key:string, value:string) : void
	{
		let updatedState = { ...this.state };
		updatedState.username.error = null;
		updatedState.password.error = null;

		switch (key)
		{
			case 'username':
				updatedState.username.value = value;
				break;
			case 'password':
				updatedState.password.value = value;
				break;
		}

		this.setState(updatedState);
	}

	render()
	{
		return (
			<form noValidate className="child-login-form form-grid -thin" onSubmit={ this.handleFormSubmit }>
				<h1>Sign In</h1>
				<OutlineInput type="text" label="Username" name="username" required={ true } form={ this.updateState.bind(this) } error={ this.state.username.error } />
				<OutlineInput type="password" label="Password" name="password" required={ true } form={ this.updateState.bind(this) } error={ this.state.password.error } />
				<div className="-space-between">
					<Link to="/forgotten-password">Forgotten password</Link>
					<div className="-flex-end">
						<Link className="-button" to="/register/children">Back</Link>
						<Button type="submit" label="Log In" classes="-solid -primary" />
					</div>
				</div>
			</form>
		);
	}
}

/** NPM Packages */
import React, { Component, EventHandler } from 'react';
import { Link } from 'react-router-dom';

/** CSS */
import './terms-of-use-form.scss';

/** Components */
import { PolicyPreview } from '../../policy-preview/policy-preview';

interface TermsOfUseFormProps
{
	acceptPolicy: ()=>void,
}

interface TermsOfUseFormState
{
	canAccept: boolean,
}

export class TermsOfUseForm extends Component<TermsOfUseFormProps, TermsOfUseFormState>
{
	constructor(props:TermsOfUseFormProps)
	{
		super(props);
		this.state = {
			canAccept: false,
		}
	}

	private handleClickEvent:EventHandler<any> = this.submit.bind(this);

	private validate() : Promise<any>
	{
		return new Promise((resolve, reject) => {
			if (this.state.canAccept)
			{
				resolve();
			}

			reject();
		});
	}

	private submit(e:MouseEvent) : void
	{
		this.validate()
		.then(() => {
			this.props.acceptPolicy();
		})
		.catch(() => {
			console.warn('User didn\'t accept the terms of use agreement');
		});
	}

	public userCanAccept() : void
	{
		const updatedState = { ...this.state };
		updatedState.canAccept = true;
		this.setState(updatedState);
	}

	render()
	{
		return (
			<div className="terms-of-use-form">
				<h1>Terms of Use</h1>
				<p>Please read the following policy before clicking the Accept button to continue.</p>
				<PolicyPreview acceptanceCallback={ this.userCanAccept.bind(this) } />
				<div className="-flex-end">
					<Link className="g-button -outline -grey" to="/register/privacy">Back</Link>
					<button onClick={ this.handleClickEvent } className={ (!this.state.canAccept) ? 'g-button is-disabled -primary -solid' : 'g-button -primary -solid' }>Accept</button>
				</div>
			</div>
		);
	}
}
